import React, { Fragment } from "react";
import styled from "styled-components";
import Title from "./Title";
import pricing1 from "assets/golfball1.png";
import pricing2 from "assets/golfball2.png";
// import play from "assets/play.png";
import play from "assets/booknow.png";
import { motion } from "framer-motion";
import { useScroll } from "./useScroll";
import { pricingAnimation } from "animation";

function Pricing() {
  const [element, controls] = useScroll();
  const plans = [
    {
      name: "Adult",
      price: 19,
    },
    {
      name: "Child",
      price: 12,
    },
    {
      name: "Senior",
      price: 16,
    },
    {
      name: "Family",
      price: 55,
    },
    {
      name: "ARCADE",
      price: 5,
    },
  ];

  const data = [
    {
      value: "4 years - 16 years",
      type: "Child",
    },
    {
      value: "3 years - Free",
      type: "Child",
    },
    {
      value: "1.5 hours",
      type: "Adult",
    },
    {
      value: "18 Holes with Amazing different themes",
      type: "Adult",
    },
    {
      value: "30 minutes free play on the 5 arcade games",
      type: "ARCADE",
    },
    {
      value: "2 Adults",
      type: "Family",
    },
    {
      value: "2 Children",
      type: "Family",
    },

    {
      value: "Extra child + $6.00",
      type: "Family",
    },
    {
      value: "Senior",
      type: "Senior",
    },
    {
      value: "Adult Student (with ID)",
      type: "Senior",
    },
  ];

  return (
    <Section id="price" ref={element}>
      <Title value="pricing" />
      <div className="background">
        <img src={pricing1} alt="background" className="bg1" />
        <img src={pricing2} alt="background" className="bg2" />
      </div>
      <div className="pricing__title">
        <p>Price list</p>
        <h2>You can pay on arrival or Book & Prepay online.</h2>
      </div>
      <div className="pricing">
        {plans.map(({ name, price }, index) => {
          return (
            <motion.div
              className="pricing__plan"
              key={index}
              variants={pricingAnimation}
              animate={controls}
              transition={{
                delay: 0.03,
                type: "tween",
                duration: 0.8,
              }}
            >
              <div className="pricing__plan__name">
                <h2>{name}</h2>
                <div className="pricing__plan__name__price">
                  <span>$</span>
                  <p>{price}</p>
                </div>
              </div>
              <div className="pricing__plan__content">
                <ul className={`pricing__plan__content__features ${name}`}>
                  {data.map(({ value, type }, index2) => {
                    return (
                      <Fragment key={index2}>
                        {name === "Adult" ? (
                          type === name ? (
                            <li>{value}</li>
                          ) : (
                            <span> </span>
                          )
                        ) : name === "Child" ? (
                          type === name ? (
                            <li>{value}</li>
                          ) : (
                            <span> </span>
                          )
                        ) : name === "Senior" ? (
                          type === name ? (
                            <li>{value}</li>
                          ) : (
                            <span> </span>
                          )
                        ) : name === "Family" ? (
                          type === name ? (
                            <li>{value}</li>
                          ) : (
                            <span> </span>
                          )
                        ) : name === "ARCADE" ? (
                          type === name ? (
                            <li>{value}</li>
                          ) : (
                            <span> </span>
                          )
                        ) : (
                          <span></span>
                        )}
                      </Fragment>
                    );
                  })}
                </ul>
                <div className="pricing__plan__content__actions">
                  {name === "Family" ? (
                    <a
                      href="https://fareharbor.com/embeds/book/caddyshack/items/197468/calendar/2022/12/?flow=305951&full-items=yes"
                      target="_blank"
                    >
                      <img src={play} height={80} lt="Order Now" />
                    </a>
                  ) : (
                    <a
                      href="https://fareharbor.com/embeds/book/caddyshack/items/196362/calendar/2022/12/?flow=305951&full-items=yes"
                      target="_blank"
                    >
                      <img src={play} height={80} alt="Order Now" />
                    </a>
                  )}
                </div>
              </div>
            </motion.div>
          );
        })}
      </div>
    </Section>
  );
}

const Section = styled.section`
  min-height: 100vh;
  padding: 5rem 0;
  position: relative;
  overflow: hidden;
  .background {
    .bg1 {
      position: absolute;
      top: -60%;
      left: -5%;
      z-index: -1;
    }
    .bg2 {
      position: absolute;
      right: 0;
      bottom: 5rem;
      z-index: -1;
    }
  }
  .pricing__title {
    margin: 6rem 10rem;
    p {
      color: var(--secondary-color);
      text-transform: uppercase;
      letter-spacing: 0.2rem;
    }
    h2 {
      color: var(--primary-color);
      font-size: 2rem;
    }
  }
  .pricing {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 0 10rem;
    &__plan {
      display: flex;
      padding: 1rem 0;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      &:nth-child(1) {
        .pricing__plan__content {
          padding: 0 0rem;
          max-width: 15rem;
        }
      }
      &:nth-child(2) {
        .pricing__plan__content {
          padding: 0 0rem;
          max-width: 15rem;
        }
      }
      &:nth-child(3) {
        .pricing__plan__content {
          padding: 0 0rem;
          max-width: 15rem;
        }
      }
      &:nth-child(4) {
        .pricing__plan__content {
          padding: 0 0rem;
          max-width: 15rem;
        }
      }
      &:nth-child(5) {
        .pricing__plan__content {
          padding: 0 0rem;
          max-width: 10rem;
        }
      }
      &__name {
        background-color: var(--primary-color);
        width: 15rem;
        height: 15rem;
        border-radius: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: white;
        h2 {
          font-size: 2rem;
          line-height: 1.3rem;
        }
        &__price {
          color: #fff;
          display: flex;
          position: relative;
          span {
            position: absolute;
            top: 1rem;
            left: -0.9rem;
            font-size: 1.3rem;
          }
          p {
            font-size: 4rem;
            font-weight: bold;
          }
        }
      }
      &__content {
        &__features {
          list-style-type: none;
          text-align: center;
          color: var(--primary-color);
          // border: 1px solid red;
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-content: start;
          .line {
            text-decoration: line-through;
          }
          margin-bottom: 1rem;
        }
        &__actions {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: var(--primary-color);
          gap: 0.5rem;
          span {
            text-transform: uppercase;
          }
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    padding: 1rem;
    background-color: var(--third-color);

    .pricing__title {
      margin: 0;
      padding: 0 2rem;
      text-align: center;
      h2 {
        font-size: 1.3rem;
        color: white;
      }
    }
    .background {
      display: none;
      color: white;
    }
    .pricing {
      grid-template-columns: 1fr;
      padding: 1rem;
      gap: 4rem;
      color: white;
      &__plan {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        border: 0.1px dashed white;
        border-radius: 1.5rem;

        &__name {
          height: 10rem;
          width: 10rem;
          h2 {
            font-size: 1.5rem;
          }
          &__price {
            p {
              font-size: 3rem;
              color: white;
            }
          }
        }
        .pricing__plan__content__features {
          color: white;
        }
        &:nth-child(1) {
          .pricing__plan__content {
            padding: 0rem;
            // border-bottom: 0.2rem solid var(--secondary-color);
            border-right: none;
          }
        }
        &:nth-child(2) {
          .pricing__plan__content {
            padding: 0rem;
            // border-bottom: 0.2rem solid var(--secondary-color);
            border-right: none;
          }
        }
        &:nth-child(3) {
          .pricing__plan__content {
            padding: 0rem;
            // border-bottom: 0.2rem solid var(--secondary-color);
            border-right: none;
          }
        }
        &:nth-child(4) {
          .pricing__plan__content {
            padding: 0rem;
            // border-bottom: 0.2rem solid var(--secondary-color);
            border-right: none;
          }
        }
        &:nth-child(5) {
          .pricing__plan__content {
            padding: 0rem;
          }
        }
      }
    }
  }
`;
export default Pricing;
